const pino = require('pino');
const { version } = require('../../../../package.json');

const getVersion = () => {
  return process.env.LOG_ENV === 'teod' ? process.env.MERGE_REQUEST_ID : version;
};

const logger = pino({
  formatters: {
    level(label, number) {
      return { level: label };
    },
    bindings(bindings) {
      return {};
    },
  },
  messageKey: 'brut',
  errorKey: 'context',
  serializers: {
    err(err) {
      return {
        stack: err.stack,
      };
    },
  },
  browser: {
    disabled: process.env.LOG_ENV === 'prod',
  },
});

const logVersion = getVersion();

const childLogger = logger.child({
  '@version': logVersion,
  appName: 'fenx',
  env: process.env.LOG_ENV || process.env.NEXT_PUBLIC_LOG_ENV,
  version: logVersion,
});

module.exports = childLogger;
