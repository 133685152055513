/**
 * Each entry in the below object shall take the below form:
 *
 * key: event name
 * value: snowplow event that maps to a tracking plan
 */

const EVENT_CATEGORY = 'pricing_calculator';

export default {
  pricing_calculator_select_universe: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_universe',
    eventLabel: '',
    eventProperty: '',
  },
  pricing_calculator_select_category: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_category',
    eventLabel: '',
    eventProperty: '',
  },
  pricing_calculator_select_brand: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_brand',
    eventLabel: '',
    eventProperty: '',
  },
  pricing_calculator_select_macromodel: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_macromodel',
    eventLabel: '',
    eventProperty: '',
  },
  pricing_calculator_select_model_variation: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_model_variation',
    eventLabel: '',
    eventProperty: '',
    preduct_id: '',
  },
  pricing_calculator_learn_more: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'learn_more',
    eventLabel: '',
    eventProperty: '',
    preduct_id: '',
  },
  pricing_calculator_list_cta: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'list_cta',
    eventLabel: '',
    eventProperty: '',
    preduct_id: '',
  },
  pricing_calculator_draft_creation: {
    eventCategory: 'list_an_item',
    eventAction: 'create_draft',
    eventLabel: EVENT_CATEGORY,
    eventProperty: '',
    preduct_id: '',
  },
};
