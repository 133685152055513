/* istanbul ignore else */
if (Array.prototype.at === undefined) {
  Array.prototype.at = function (index) {
    const i = index < 0 ? this.length + index : index;
    return this[i];
  };
}

/* istanbul ignore else */
if (String.prototype.at === undefined) {
  String.prototype.at = function (index) {
    const i = index < 0 ? this.length + index : index;
    return this[i];
  };
}

/* istanbul ignore next  */
if (typeof window !== 'undefined') {
  const requestIdleCallbackPollyfill = (
    cb: ({ didTimeout, timeRemaining }: { didTimeout: boolean; timeRemaining: () => number }) => void,
  ) => {
    const timeoutId = setTimeout(function () {
      const start = Date.now();
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
    return Number(timeoutId);
  };

  window.requestIdleCallback = window.requestIdleCallback || requestIdleCallbackPollyfill;

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function (id) {
      clearTimeout(id);
    };
}

export {};
