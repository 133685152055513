import React, { FCWithChildren, useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { MySizes as Sizes } from '@interfaces/models/mySizes';
import logger from '@helpers/utils/logger/client';
import { AxiosError } from 'axios';
import useUser from '@hooks/user/use-user';
import { getSearchPreferences, saveSearchPreferences } from '@api/search-preferences';

export type UserContextValues = {
  userSizes: Sizes;
  setUserSizes: React.Dispatch<React.SetStateAction<Sizes>>;
  userSizesError: AxiosError;
  areUserSizesLoading: boolean;
  editUserSizes: (val: Sizes) => Promise<Sizes | null>;
};

export type UserProviderProps = {
  userSizes?: Sizes;
};

const UserContext = createContext<UserContextValues>({} as UserContextValues);

const UserProvider: FCWithChildren<UserProviderProps> = (props): React.JSX.Element => {
  const { userSizes: currentUserSizes, children } = props;

  const { user, isAuthenticated } = useUser();

  const [userSizes, setUserSizes] = useState<Sizes>(currentUserSizes ?? null);
  const [userSizesError, setUserSizesError] = useState<AxiosError>(null);
  const [areUserSizesLoading, setAreUserSizesLoading] = useState<boolean>(false);

  const editUserSizes = async (preferences: Sizes) => {
    setAreUserSizesLoading(true);
    try {
      const {
        data: { data },
      } = await saveSearchPreferences(preferences);
      setUserSizesError(null);
      return data;
    } catch (err) {
      setUserSizesError(err);
      logger.error(err, 'Error occurred at action: save user preferences');
    } finally {
      setAreUserSizesLoading(false);
    }
  };

  const getUserSizes = async (): Promise<void> => {
    setAreUserSizesLoading(true);
    try {
      const preferences = await getSearchPreferences();
      setUserSizes(preferences);
    } catch (err) {
      logger.error(err, 'Error occurred while fetching');
    } finally {
      setAreUserSizesLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      await Promise.allSettled([
        // Dont fetch user sizes if we got them on the server already
        ...(!userSizes ? [getUserSizes()] : []),
      ]);
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user) {
      setUserSizes(() => null);
    }
  }, [user]);

  const value = {
    userSizes,
    setUserSizes,
    userSizesError,
    editUserSizes,
    areUserSizesLoading,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
