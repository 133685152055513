import { axios, getAxiosConfig } from '@config/axios';
import { Order } from '@interfaces/models/order';
import Environment from '@config/index';
import { Product } from '@interfaces/models/product';
import { OrderLine } from '@interfaces/models/orderLine';
import { AddToCartPayload } from '@interfaces/api/payloads/orders';
import { ApiResponse } from '@interfaces/api';
import logger from '@helpers/utils/logger/client';

/**
 * Note: All requests that contain "/orders/" in the url are set to custom timeout of 15s. This can be changed in @config/axios.
 */
export default class OrdersService {
  static apiBaseUrl = Environment.apiBaseUrl;

  static async getCart(): Promise<Order | null> {
    try {
      const axiosConfig = getAxiosConfig();
      const {
        data: { data },
      } = await axios.get<ApiResponse<Order | null>>(`${this.apiBaseUrl}/orders/current-light`, axiosConfig);
      return data;
    } catch (err) {
      logger.info(err, 'Error fetching current orders');
      return err;
    }
  }

  static async addProductToCart(payload: AddToCartPayload): Promise<OrderLine> {
    const axiosConfig = getAxiosConfig();
    return axios.post<AddToCartPayload, OrderLine>(
      `${this.apiBaseUrl}/orders/current/order-lines-light`,
      payload,
      axiosConfig,
    );
  }

  /**
   * Last updated on 21.4.2022.
   * Light cart api accepts both singular orderLineId and productId, as well as multiple ids for both properties separated with ','.
   * Example: "1728292,2749393"
   * Web version currently supports only single product removal for now. In case that changes, please rename both the property names and
   * type for both properties.
   */
  static removeProductFromCart(orderLineId: OrderLine['id'] = '', productId: Product['id'] = ''): Promise<unknown> {
    const axiosConfig = getAxiosConfig();
    return axios.delete(`${this.apiBaseUrl}/orders/current/order-lines-light`, {
      data: {
        orderLineIds: orderLineId,
        productIds: productId,
      },
      ...axiosConfig,
    });
  }
}
