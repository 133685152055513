export default class MissingLayoutPropertyError extends Error {
  constructor() {
    super(
      'Layout property is missing from the page, make sure you type your page with "NextPageWithLayout" and add the Layout property. \n' +
        'Example:\n' +
        'const ExamplePage: NextPageWithLayout<ExamplePageProps> = () => {};\n' +
        'ExamplePage.Layout = [Layout1, Layout2, ...];',
    );
    this.name = 'MissingLayoutPropertyError';
  }
}
