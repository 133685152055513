import { getAxiosConfig, axios, ServerAxiosRequestData } from '@config/axios';
import Environment from '@config/index';
import { MySizes as UserSizes } from '@interfaces/models/mySizes';

const BASE_URL = Environment.apiBaseUrl;

export const getSearchPreferences = async (serverAxiosRequestData?: ServerAxiosRequestData): Promise<UserSizes> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);
  const {
    data: { data },
  } = await axios.get(`${BASE_URL}/users/me/search-preferences`, axiosConfig);
  return data;
};

export const getSearchPreferencesOptions = async () => {
  const axiosConfig = getAxiosConfig();
  return axios.get(`${BASE_URL}/users/me/search-preferences-options`, axiosConfig);
};

export const saveSearchPreferences = async (payload: UserSizes) => {
  const axiosConfig = getAxiosConfig();
  return axios.post(`${BASE_URL}/users/me/search-preferences`, payload, axiosConfig);
};
