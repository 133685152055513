import { Env } from '@interfaces/models/environment';
import PreferenceCookie from '@services/preferences-cookie-service';
import Environment from './environment';

const getEnvironment = (): Env => {
  // This defaults to 'en' on server. When links get rewritten to relative only, we can remove this stuff.
  const { language } = PreferenceCookie.parseCookieCK();
  const currentEnv = Environment;

  if (language !== 'en') {
    const subdomain = language === 'ko' ? 'kr' : language;
    currentEnv.baseHost = currentEnv.baseHost?.replace('www', subdomain);
    currentEnv.legacyLink = currentEnv.legacyLink?.replace('www', subdomain);
  }

  return currentEnv;
};

export default getEnvironment();
