import React, { FC, FCWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TagContainer, { TrackingFunctionName } from '@enums/tagContainer';
import { DataLayerInterface } from '@interfaces/models/dataLayerInterface';
import Environment from '@config/index';
import { AnalyticsEvent } from '@interfaces/models/internal/analytics-event';
import logger from '@helpers/utils/logger/client';
import Script from 'next/script';
import { useRouter } from 'next/router';
import NextHead from 'next/head';
import { BooleanString, NonLogged } from '@interfaces/models/types';
import { datadogRum } from '@datadog/browser-rum-slim';
import { checkDataLayer } from '@hooks/analytics/check-datalayer-and-log-errors';
import { isBot } from '@helpers/is-bot';

// initialState will not be in the SSR.
const initialState: Partial<DataLayerInterface> = {
  new_platform: BooleanString.True,
  platform: 'fenx',
  anonymous_id: '',
  authAttempted: false,
  env_work: Environment.analytics.envName,
  env_channel: 'desktop',
  responsive: BooleanString.False,
  cust_firstname: '',
  user_id: '',
  user_email: '',
  user_accountLevel: NonLogged.Value,
  user_buyerStatus: NonLogged.Value,
  user_sellerStatus: NonLogged.Value,
  user_seller_rating: NonLogged.Value,
  user_fashion_activist: NonLogged.Value,
  user_category: NonLogged.Value,
  cookie_analytics: 'default_false',
  cookie_customization: 'default_false',
  cookie_media: 'default_false',
  user_iphoneApp: NonLogged.Value,
  user_androidApp: NonLogged.Value,
  index_name: '',
  order_products: [],
  order_amount_ati_with_sf: '',
  product_unitprice_ati: '',
  onsite_campaign_id: '',
  filtered_campaign_id: '',
  product_discount_ati: '',
  env_template: '',
  catalog_version: '',
  pageId: '',
  pageName: '',
  pageCategory: '',
  pageUrl: '',
  entry_id: '',
  entry_title: '',
  screen_category: '',
  screen_name: '',
  screen_subcategory: '',
};

const DATALAYER_KEY = 'dataLayer';

// Some field just tracking one time. Not store in local storage to avoid incorrect
// when move to another page without reset
// const NOT_STORE_IN_LOCAL_STORAGE = ['order_confirmation_checkout_flow'];

export type SendEventCallback = (...events: AnalyticsEvent[]) => void;

const functions = new Map<TagContainer, TrackingFunctionName>([
  [TagContainer.Media, 'tc_events_1'],
  [TagContainer.Analytics, 'tc_events_7'],
]);

interface IScriptsEventData {
  isTcEvents1Loaded: boolean;
  isTcEvents7Loaded: boolean;
}

export interface AnalyticContextProps {
  sendEvent: SendEventCallback;
  updateDataLayer: (data: Partial<DataLayerInterface>) => void;
  scriptsEventData?: IScriptsEventData;
}

type UpdateDataLayerProps = {
  data: Partial<DataLayerInterface>;
  userAgent?: string;
};

export const UpdateDataLayerComponent: FC<UpdateDataLayerProps> = ({ data, userAgent }) => {
  if (isBot(userAgent)) {
    return null;
  }
  return (
    <NextHead>
      <script
        // eslint-disable-next-line jam3/no-sanitizer-with-danger
        dangerouslySetInnerHTML={{
          __html: `window.tc_vars = window.tc_vars || {}; window.tc_vars = Object.assign({}, window.tc_vars, ${JSON.stringify(
            data,
          )});`,
        }}
      ></script>
    </NextHead>
  );
};

const AnalyticStateContext = React.createContext<AnalyticContextProps>({} as never);

function pollForTcEvents({ callback, functionName }: { callback: () => void; functionName: string }) {
  const startTime = Date.now();
  const pollingInterval = 100; // Poll every 100ms
  const timeout = 1000; // Poll for 1 seconds

  const pollIntervalId = setInterval(function () {
    if (typeof window[functionName] === 'function') {
      clearInterval(pollIntervalId);
      callback(); // Call the provided callback function
    }

    if (Date.now() - startTime >= timeout) {
      clearInterval(pollIntervalId);
    }
  }, pollingInterval);
}

const AnalyticsProvider: FCWithChildren = ({ children }) => {
  const defaultContainer = TagContainer.Analytics;
  const isUserABot = isBot();
  const { verbose } = Environment.analytics;
  const [dataLayer, setDataLayer] = useState(() => {
    if (typeof window === 'undefined') {
      // datalayer on the serverside doesn't have initial value,
      // due to override conflict with the localstorage
      return {};
    }
    try {
      const currentLocalStorageValue = JSON.parse(
        localStorage.getItem(DATALAYER_KEY) || 'null',
      ) as Partial<DataLayerInterface>;
      return { ...initialState, ...currentLocalStorageValue, ...window.tc_vars };
    } catch (e) {
      datadogRum.addError(e);
      return { ...initialState, ...window.tc_vars };
    }
  });
  const [isTcEvents1Loaded, setIsTcEvents1Loaded] = useState<boolean>(false);
  const [isTcEvents7Loaded, setIsTcEvents7Loaded] = useState<boolean>(false);
  const { query } = useRouter();
  const updateDataLayer = useCallback((data: Partial<DataLayerInterface>) => {
    setDataLayer((dl) => ({
      ...dl,
      ...data,
    }));
  }, []);

  const event7LoadCallback = () => {
    setIsTcEvents7Loaded(true);
  };

  const event1LoadCallback = () => {
    setIsTcEvents1Loaded(true);
  };

  useEffect(() => {
    window.tc_vars = Object.assign({}, window.tc_vars, dataLayer);

    /* 
   #Incident 427
   NOT_STORE_IN_LOCAL_STORAGE.forEach((key) => {
      delete window.tc_vars[key];
    });*/

    localStorage.setItem(DATALAYER_KEY, JSON.stringify(dataLayer));
  }, [dataLayer, isTcEvents1Loaded, isTcEvents7Loaded]);

  // check critical datalater and add logs if there is any issue
  useEffect(() => {
    if (typeof window !== 'undefined' && isTcEvents1Loaded && isTcEvents7Loaded) {
      checkDataLayer();
    }
  }, [isTcEvents1Loaded, isTcEvents7Loaded]);

  const sendTrackingEvents = (event, functionName: TrackingFunctionName) => {
    if (typeof window === 'undefined') {
      return;
    }
    if (typeof window[functionName] === 'function') {
      if (verbose) {
        logger.info(event, `[Tracking] Send "${event.type}" event`);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window[functionName]({}, event.type, event.payload || {});
    } else {
      // To delay event tracking sendout if tracking script is not ready
      pollForTcEvents({ callback: () => sendTrackingEvents(event, functionName), functionName });
    }
  };

  const sendEvent = useCallback<SendEventCallback>((...events: AnalyticsEvent[]) => {
    if (typeof window === 'undefined') {
      return;
    }
    events.forEach((event) => {
      event.container = event.container || defaultContainer;
      const functionName = functions.get(event.container);

      try {
        sendTrackingEvents(event, functionName);
      } catch (e) {
        if (verbose) {
          logger.info(`[Tracking] Failed for "${event.type}"`);
          logger.info(e);
        }
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      sendEvent,
      updateDataLayer,
      scriptsEventData: {
        isTcEvents1Loaded,
        isTcEvents7Loaded,
      },
    }),
    [isTcEvents1Loaded, isTcEvents7Loaded, sendEvent, updateDataLayer],
  );

  return (
    <AnalyticStateContext.Provider value={value}>
      {Environment.disableAnalytics ||
        (!isUserABot && (
          <>
            <UpdateDataLayerComponent
              data={{
                ...dataLayer,
                catalog_version: query?.product_id ? 'hero pdp' : '',
              }}
            />
            <Script
              id="tc_script_7"
              strategy="lazyOnload"
              defer
              src={`https://cdn.tagcommander.com/310/tc_Vestiairecollective_7.js?ts=${Date.now()}`}
              onLoad={event7LoadCallback}
            />
            <Script
              id="tc_script_1"
              strategy="lazyOnload"
              defer
              src={`https://cdn.tagcommander.com/310/tc_Vestiairecollective_1.js?ts=${Date.now()}`}
              onLoad={event1LoadCallback}
            />
          </>
        ))}
      {children}
    </AnalyticStateContext.Provider>
  );
};

const useAnalytics = () => useContext(AnalyticStateContext);

export { AnalyticsProvider, useAnalytics, AnalyticStateContext };
