import React, { FCWithChildren } from 'react';
import { Preferences } from '@interfaces/models/preferences';

type PreferencesProviderProps = {
  preferences: Preferences;
};

type PreferencesContextValues = Preferences;

const PreferencesContext = React.createContext<PreferencesContextValues>({} as never);

const PreferencesProvider: FCWithChildren<PreferencesProviderProps> = (props) => {
  const { preferences, children } = props;

  return <PreferencesContext.Provider value={preferences}>{children}</PreferencesContext.Provider>;
};

const usePreferences = (): PreferencesContextValues => {
  const context: PreferencesContextValues = React.useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error('usePreferences must be used within a PreferencesProvider');
  }
  return context;
};

export { PreferencesProvider, usePreferences };
