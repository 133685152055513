import { useState, useRef, useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

const scrollYThreshold = 0;

const useOnReveal = (onReveal: () => void, threshold: number | undefined = undefined, trackUserScroll = true) => {
  const containerRef = useRef(null);
  const [userScrollStatus, setUserScrollStatus] = useState(!trackUserScroll);
  const entry = useIntersectionObserver(containerRef, { freezeOnceVisible: true, threshold });
  const isVisible = !!entry?.isIntersecting;
  const [viewCount, setViewCount] = useState(isVisible ? 1 : 0);

  const onScroll = () =>
    window.requestAnimationFrame(() => {
      const currentScrollYPosition = window.scrollY;
      if (currentScrollYPosition > scrollYThreshold && !userScrollStatus) {
        setUserScrollStatus(true);
      }
    });

  useEffect(() => {
    if (trackUserScroll) {
      window.addEventListener('scroll', onScroll);
    }
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (userScrollStatus && isVisible && viewCount === 0) {
      setViewCount(1);
      // Only trigger onReveal event when dom goes into user view for the first time
      onReveal();
    }
  }, [isVisible, userScrollStatus, viewCount]);

  return { containerRef };
};

export default useOnReveal;
