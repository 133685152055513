import {
  BooleanString,
  BuyerStatus,
  SellerStatus,
  TrackingSellerRating,
  UserAccountLevel,
  UserCategory,
  UserState,
  NonLogged,
  YesNoString,
  DataLayerState,
  PageUrlState,
} from '@interfaces/models/types';
import { User } from '@interfaces/models/user';
import UserBadge from '@enums/userBadge';
import { Product } from '@interfaces/models/product';
import { AvailableCountryISOCodes } from '@interfaces/models/country';

function getUserCategory(user: User): UserCategory {
  const { productsSold, productsBought } = user.statistics;

  if (productsSold > 0 && productsBought > 0) {
    return UserCategory.Mixed;
  }
  if (productsSold > 0) {
    return UserCategory.Seller;
  }
  if (productsBought > 0) {
    return UserCategory.Buyer;
  }
  return UserCategory.Visitor;
}

function getBuyerStatus(user: User): BuyerStatus {
  const nbBought = user.statistics.productsBought;

  if (nbBought >= 10) {
    return BuyerStatus.TopBuyer;
  }
  if (nbBought >= 2) {
    return BuyerStatus.RepeatedBuyer;
  }
  if (nbBought === 1) {
    return BuyerStatus.FirstBuyer;
  }

  return BuyerStatus.Viewer;
}

function getSellerStatus(user: User): SellerStatus {
  const { productsSold, productsListed } = user.statistics;

  if (productsSold >= 10) {
    return SellerStatus.TopSeller;
  }
  if (productsSold >= 2) {
    return SellerStatus.RepeatedSeller;
  }
  if (productsSold === 1) {
    return SellerStatus.FirstSeller;
  }
  if (productsListed >= 1) {
    return SellerStatus.Depositor;
  }

  return SellerStatus.Viewer;
}

function getSellerRating(userBadges: UserBadge[]): TrackingSellerRating {
  if (userBadges?.includes(UserBadge.ExpertSeller)) {
    return TrackingSellerRating.Expert;
  }
  if (userBadges?.includes(UserBadge.TrustedSeller)) {
    return TrackingSellerRating.Trusted;
  }

  return TrackingSellerRating.Common;
}

export const USER_INITIAL_STATE: UserState = {
  cust_firstname: '',
  user_id: '',
  user_email: '',
  user_accountLevel: NonLogged.Value,
  user_category: NonLogged.Value,
  user_buyerStatus: NonLogged.Value,
  user_sellerStatus: NonLogged.Value,
  user_androidApp: NonLogged.Value,
  user_iphoneApp: NonLogged.Value,
  user_seller_rating: NonLogged.Value,
  user_fashion_activist: NonLogged.Value,
};

export enum CheckoutEnvTemplate {
  CART = 'cartend',
  CHECKOUT = 'checkout',
  CART_SUMMARY = 'cartsummary',
}

export function mapUser(user: User): UserState {
  const userState: UserState = {
    user_id: user.id,
    user_email: user.email || '',
    cust_firstname: user.firstname.toLowerCase(),
    user_accountLevel: UserAccountLevel.Regular,
    user_category: getUserCategory(user),
    user_buyerStatus: getBuyerStatus(user),
    user_sellerStatus: getSellerStatus(user),
    user_iphoneApp: BooleanString.False,
    user_androidApp: BooleanString.False,
    user_seller_rating: getSellerRating(user.badges),
    user_fashion_activist: user.badges.includes(UserBadge.FashionActivist) ? BooleanString.True : BooleanString.False,
  };

  if (user.apps && user.apps.length) {
    user.apps.forEach((app) => {
      if (app.key === 'ios') {
        userState.user_iphoneApp = app.value !== '0' ? BooleanString.True : BooleanString.False;
      }

      if (app.key === 'android') {
        userState.user_androidApp = app.value !== '0' ? BooleanString.True : BooleanString.False;
      }
    });
  }

  return userState;
}

/**
 * Data related to the current product.
 * These variables should be set for product pages.
 *
 * - `product_unitprice_ati` Unit price of the product all tax included (local currency)
 * - `product_unitprice_ati_eur` Unit price of the product all tax included (EUR currency)
 */
export const PRODUCT_INITIAL_STATE = {
  profilId: '',
  product_id: '',
  product_name: '',
  product_discount_ati: '',
  product_unitprice_ati: '',
  product_discount_ati_eur: '',
  product_unitprice_ati_eur: '',
  product_brand: '',
  product_univers: '',
  product_category: '',
  product_subcategory: '',
};

export function mapProduct(product: Product): DataLayerState {
  const state = {
    ...PRODUCT_INITIAL_STATE,
    product_id: product.id,
    product_name: product.name,
    profilId: product.seller.id,
    owner_seller_rating: getSellerRating(product.seller.badges),
    owner_fashion_activist: product.seller.badges.includes(UserBadge.FashionActivist)
      ? BooleanString.True
      : BooleanString.False,
    owner_badge_conformity: product.seller.sellerRating.goals.tags.conformity ? YesNoString.Yes : YesNoString.No,
    owner_badge_shipping: product.seller.sellerRating.goals.tags.shipping ? YesNoString.Yes : YesNoString.No,
    owner_badge_volume: product.seller.sellerRating.goals.tags.volume ? YesNoString.Yes : YesNoString.No,
    seller_id: product.seller?.id,
    product_universe: product.universe?.name,
    product_category: product.category?.name,
    product_subcategory: product.subcategory?.name,
    product_brand: product.brand?.name,
    product_unit_price: product.price?.formatted,
    product_country: product.seller?.country,
    product_currency: product.price?.currency,
    product_crossborder_transaction: `${product.dutyAndTax?.cents > 0}`,
    product_ds_eligible: `${product.flags?.includes('direct-shipping')}`,
  };

  const currentPrice = product.price.cents / 100;
  const regularPrice = product.regularPrice ? product.regularPrice.cents / 100 : 0;

  if (regularPrice > 0) {
    state.product_unitprice_ati = regularPrice.toFixed(2);
    state.product_discount_ati = currentPrice.toFixed(2);
  } else {
    state.product_unitprice_ati = currentPrice.toFixed(2);
  }
  return state;
}

export function mapProductPageUrl(product: Product): PageUrlState {
  let brand: string, universe: string, category: string, subCategory: string;

  if (product.universe) {
    universe = product.universe.name.toLowerCase();
  }

  if (product.category.parent) {
    category = product.category.parent.name;
  }

  if (product.category) {
    subCategory = product.category.name;
  }

  if (product.brand) {
    brand = product.brand.name.toUpperCase();
  }

  return {
    pageUrl: `/${universe}/productPage/${category}/${subCategory}/${brand}`,
  };
}

export function lookupCountry(user: User, fallback: AvailableCountryISOCodes) {
  if (user && user.address) {
    return user.address.country.isoCode;
  }
  return fallback;
}
