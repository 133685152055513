export enum HomePageTemplate {
  DEFAULT = 'control',
  VARIANT = 'variant',
}

export enum ContentStackPageNames {
  HOME_PAGE = 'homepage',
  GLOBAL_HOME_PAGE = 'homepage_global',
  NEW_VISITOR_PAGE = 'new_visitor_homepage',
  CHANNEL_PAGE = 'channels',
  MY_COMMUNITY_PAGE = 'my_community',
  TOP_SELLERS_PAGE = 'top_sellers',
  RAAS_PAGE = 'raas',
  DAILY_DEALS_PAGE = 'daily_deals',
  DS_EXPRESS_DELIVERY_WEB_PAGE = 'web_ds_express_delivery',
  TALKING_FASHION_PAGE_WEB_PAGE = 'web_talking_fashion',
  DS_EXPRESS_DELIVERY_APP_PAGE = 'app_ds_express_delivery',
  HOME_MADE_BY_VC_PAGE = 'homemade_by_vc',
  LANDING_WITH_DESCRIPTION_AND_PRODUCT_PAGE = 'copy_of_landing_with_description_and_products',
  CUSTOM_PAGE = 'generic_page_template',
  EDITORIAL_PAGE = 'editorial_page',
  CAMPAIGN_PAGE = 'campaign_page',
  LIVEPREVIEW_PAGE = 'livepreview_page',
  FAVORITE_PAGE = 'favorites_empty_state',
}

export enum ContentStackComponentKeys {
  CAMPAIGN = 'campaign',
  CAMPAIGN_V2 = 'campaign_component_v2',
  CHANNEL = 'channel',
  CHANNEL_V2 = 'channel_component',
  MERCH = 'merch',
  MERCH_V2 = 'merch_component',
  // for api v1 - start (remove)
  SEC_1_CAMPAIGN = 'sec1_campaign',
  SEC_1_CHANNEL = 'sec1_channel',
  SEC_1_MERCH = 'sec1_merch',
  SEC_2_MERCH = 'sec2_merch',
  SEC_3_MERCH = 'sec3_merch',
  SEC_4_MERCH = 'sec4_merch',
  SEC_5_MERCH = 'sec5_merch',
  VOUCHER_BANNER_1 = 'voucher_banner_1',
  VOUCHER_BANNER_2 = 'voucher_banner_2',
  VOUCHER_BANNER_3 = 'voucher_banner_3',
  // for api v1 - end
  INFO_BANNER = 'info_banner',
  INFO_BANNER_V2 = 'info_banner_component',
  POST_FEED = 'post_feed',
  POST_FEED_V2 = 'post_feed_component',
  VOUCHER_BANNER = 'voucher_banner_component',
  CONTENT_SECTION_1 = 'content_section_1', // v1
  DESCRIPTION = 'description', //v1
  DESCRIPTION_V2 = 'description_component', //v2
  // for api v1 - start (remove)
  SEC_2_CAMPAIGN = 'sec2_campaign',
  SEC_2_CONTAINER = 'sec2_container',
  // for api v1 - end
  CONTENT_SECTION_2 = 'content_sec2',
  PRODUCT_FEED = 'Product_Feed',
  PRODUCT_FEED_V2 = 'product_feed',
  // for api v1 - start (remove)
  PRODUCT_FEED_1 = 'product_feed_1',
  PRODUCT_FEED_2 = 'product_feed_2',
  PRODUCT_FEED_3 = 'product_feed_3',
  // for api v1 - end
  // new general cms component
  PRODUCT_COMPONENT = 'product_component',
  PRODUCT_RECENTLY_VIEWED = 'product_recently_viewed',
  PRODUCT_NEW_IN = 'product_new_in', // has to be removed
  PRODUCT_WE_LOVE = 'product_we_love', // has to be removed
  MODULAR_BLOCK = 'modularBlocks',
  TEXT_CONTAINER = 'Text_Container',
  VIDEO_CONTAINER = 'Edito_Video_Block',
  VISUAL_CONTAINER = 'Visual_Block_Container',
  PRODUCT_CONTAINER = 'Product_Block_Container',
  BANNER_CONTAINER = 'Edito_Banner_Container',
  TEXT_COMPONENT = 'Text',
  SALE_BLOCK = 'sale_block',
}

export enum ContentStackContainers {
  campaign_component = ContentStackComponentKeys.CAMPAIGN,
  channel_component = ContentStackComponentKeys.CHANNEL,
  info_banner_component = ContentStackComponentKeys.INFO_BANNER,
  merch_component = ContentStackComponentKeys.MERCH,
  post_feed_component = ContentStackComponentKeys.POST_FEED,
  sale_component = ContentStackComponentKeys.SALE_BLOCK,
}

export const CMS_LANDING_PAGES = [
  ContentStackPageNames.RAAS_PAGE,
  ContentStackPageNames.CUSTOM_PAGE,
  ContentStackPageNames.CHANNEL_PAGE,
  ContentStackPageNames.DAILY_DEALS_PAGE,
  ContentStackPageNames.TOP_SELLERS_PAGE,
  ContentStackPageNames.MY_COMMUNITY_PAGE,
  ContentStackPageNames.HOME_MADE_BY_VC_PAGE,
  ContentStackPageNames.DS_EXPRESS_DELIVERY_APP_PAGE,
  ContentStackPageNames.DS_EXPRESS_DELIVERY_WEB_PAGE,
  ContentStackPageNames.TALKING_FASHION_PAGE_WEB_PAGE,
  ContentStackPageNames.LANDING_WITH_DESCRIPTION_AND_PRODUCT_PAGE,
];
export const CMS_PAGES = [
  ContentStackPageNames.HOME_PAGE,
  ContentStackPageNames.CAMPAIGN_PAGE,
  ContentStackPageNames.GLOBAL_HOME_PAGE,
  ContentStackPageNames.EDITORIAL_PAGE,
  ContentStackPageNames.NEW_VISITOR_PAGE,
  ...CMS_LANDING_PAGES,
];

export const CMS_LANDING_PAGE_SECTIONS = {
  [ContentStackPageNames.CHANNEL_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.CONTENT_SECTION_1,
    ContentStackComponentKeys.PRODUCT_FEED_1,
  ],
  [ContentStackPageNames.MY_COMMUNITY_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.CONTENT_SECTION_1,
    ContentStackComponentKeys.SEC_1_CAMPAIGN,
    ContentStackComponentKeys.PRODUCT_FEED_1,
    ContentStackComponentKeys.SEC_2_CAMPAIGN,
  ],
  [ContentStackPageNames.TOP_SELLERS_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.CONTENT_SECTION_1,
  ],
  [ContentStackPageNames.RAAS_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.PRODUCT_FEED_1,
    ContentStackComponentKeys.CONTENT_SECTION_1,
    ContentStackComponentKeys.PRODUCT_FEED_2,
  ],
  [ContentStackPageNames.DAILY_DEALS_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.CONTENT_SECTION_1,
    ContentStackComponentKeys.PRODUCT_FEED_1,
  ],
  [ContentStackPageNames.DS_EXPRESS_DELIVERY_WEB_PAGE]: [
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.SEC_1_MERCH,
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.SEC_2_MERCH,
    ContentStackComponentKeys.PRODUCT_FEED_1,
    ContentStackComponentKeys.CAMPAIGN,
    ContentStackComponentKeys.PRODUCT_FEED_2,
  ],
  [ContentStackPageNames.DS_EXPRESS_DELIVERY_APP_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.SEC_1_MERCH,
    ContentStackComponentKeys.CAMPAIGN,
  ],
  [ContentStackPageNames.TALKING_FASHION_PAGE_WEB_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.SEC_1_MERCH,
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.CONTENT_SECTION_2,
    ContentStackComponentKeys.PRODUCT_FEED_1,
  ],
  [ContentStackPageNames.HOME_MADE_BY_VC_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.CONTENT_SECTION_1,
    ContentStackComponentKeys.PRODUCT_FEED_1,
  ],
  [ContentStackPageNames.LANDING_WITH_DESCRIPTION_AND_PRODUCT_PAGE]: [
    ContentStackComponentKeys.DESCRIPTION,
    ContentStackComponentKeys.PRODUCT_FEED_1,
  ],
  [ContentStackPageNames.CUSTOM_PAGE]: [ContentStackComponentKeys.MODULAR_BLOCK],
};

export const CMS_PAGE_SECTIONS = (isLivePreview: boolean) => ({
  [ContentStackPageNames.HOME_PAGE]: [
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.SEC_1_CAMPAIGN,
    ContentStackComponentKeys.SEC_1_CHANNEL,
    ContentStackComponentKeys.SEC_1_MERCH,
    ContentStackComponentKeys.PRODUCT_RECENTLY_VIEWED,
    ContentStackComponentKeys.PRODUCT_WE_LOVE,
    ContentStackComponentKeys.SEC_2_MERCH,
    ContentStackComponentKeys.POST_FEED,
    ContentStackComponentKeys.PRODUCT_NEW_IN,
  ],
  [ContentStackPageNames.GLOBAL_HOME_PAGE]: [
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.VOUCHER_BANNER_1,
    ContentStackComponentKeys.SEC_1_CHANNEL,
    ContentStackComponentKeys.SEC_1_CAMPAIGN,
    ...(isLivePreview ? [ContentStackComponentKeys.CAMPAIGN] : []),
    ...(isLivePreview ? [ContentStackComponentKeys.SEC_1_MERCH] : []),
    ContentStackComponentKeys.SEC_2_MERCH,
    ContentStackComponentKeys.PRODUCT_RECENTLY_VIEWED,
    ContentStackComponentKeys.PRODUCT_WE_LOVE,
    ContentStackComponentKeys.SEC_3_MERCH,
    ContentStackComponentKeys.POST_FEED,
    ContentStackComponentKeys.PRODUCT_NEW_IN,
    ContentStackComponentKeys.VOUCHER_BANNER_2,
    ContentStackComponentKeys.VOUCHER_BANNER_3,
  ],
  [ContentStackPageNames.EDITORIAL_PAGE]: [ContentStackComponentKeys.MODULAR_BLOCK],
  [ContentStackPageNames.NEW_VISITOR_PAGE]: [
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.CAMPAIGN,
    ContentStackComponentKeys.SEC_1_MERCH,
    ContentStackComponentKeys.SEC_2_CONTAINER,
    ContentStackComponentKeys.PRODUCT_FEED_1,
    ContentStackComponentKeys.SEC_3_MERCH,
    ContentStackComponentKeys.SEC_4_MERCH,
    ContentStackComponentKeys.SEC_5_MERCH,
    ContentStackComponentKeys.PRODUCT_FEED_2,
  ],
  [ContentStackPageNames.LIVEPREVIEW_PAGE]: [
    ContentStackComponentKeys.INFO_BANNER,
    ContentStackComponentKeys.CHANNEL,
    ContentStackComponentKeys.CAMPAIGN,
    ContentStackComponentKeys.MERCH,
    ContentStackComponentKeys.POST_FEED,
  ],
  ...CMS_LANDING_PAGE_SECTIONS,
});
