import React, { useCallback } from 'react';
import Icon from '@components/atoms/icon/icon';
import useOnReveal from '@hooks/use-on-reveal';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { useTranslation } from 'next-i18next';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import clsx from 'clsx';
import styles from './usp.module.scss';

interface IUspProps {
  pageType: 'hero_pdp' | 'plp' | 'pdp' | 'cms' | 'error';
}

type UspBlock = {
  icon: string;
  title: string;
  description: string;
  link?: string;
  linkTitle?: string;
};

type UspBlockList = {
  [name: string]: UspBlock;
};

interface IUspBanner {
  title?: string;
  list: UspBlock[];
}

const PAGES_WITH_SLIDER = ['hero_pdp', 'plp', 'pdp', 'cms'];

const USP_BLOCKS: UspBlockList = {
  negotiation: {
    icon: 'mmao-large',
    title: 'USP.BLOCK_1.TITLE',
    description: 'USP.BLOCK_1.CONTENT',
    linkTitle: 'USP.BLOCK_1.LINK_TITLE',
    link: '/journal/make-an-offer/',
  },
  authentication: {
    icon: 'authentication-large',
    title: 'USP.BLOCK_2.TITLE',
    description: 'USP.BLOCK_2.CONTENT',
    linkTitle: 'USP.BLOCK_2.LINK_TITLE',
    link: '/journal/trust-inside-authentication/',
  },
  deals: {
    icon: 'handbag-large',
    title: 'USP.BLOCK_3.TITLE',
    description: 'USP.BLOCK_3.CONTENT',
    linkTitle: 'USP.BLOCK_3.LINK_TITLE',
    link: '/c/flash-price-drops-10474/',
  },
  sell: {
    icon: 'sell-item-large',
    title: 'USP.BLOCK_4.TITLE',
    description: 'USP.BLOCK_4.CONTENT',
    linkTitle: 'USP.BLOCK_4.LINK_TITLE',
    link: '/journal/start-selling/',
  },
  // TO BE UPDATED LATER
  // for Error page, since the whole page is not translated yet we keep hardcoded texts for the moment
  customer: {
    icon: 'customer-service-large',
    title: 'Customer Service',
    description: 'Always-on customer support. We got you via email or chat.',
  },
  authentication_error: {
    icon: 'authentication-large',
    title: 'Legit luxury',
    description: 'Shop real pre-loved fashion, thanks to our 50+ authenticators.',
  },
  daily: {
    icon: 'handbag-large',
    title: 'Refreshed daily',
    description: '20k+ new pieces every day. That one you want? We have it.',
  },
  payment: {
    icon: 'payment-verif-large',
    title: 'Secured Payments',
    description: 'Ensuring your transactions are always protected.',
  },
};

const USP_PLP: IUspBanner = {
  title: 'USP.TITLE',
  list: [USP_BLOCKS.negotiation, USP_BLOCKS.authentication, USP_BLOCKS.deals, USP_BLOCKS.sell],
};

const USP_ERROR: IUspBanner = {
  list: [USP_BLOCKS.authentication_error, USP_BLOCKS.daily, USP_BLOCKS.customer, USP_BLOCKS.payment],
};

const Usp: React.FC<IUspProps> = ({ pageType }) => {
  const { sendAnalyticEvent } = useAnalyticEvents('usp_block');
  const { t } = useTranslation();
  const isSlider = PAGES_WITH_SLIDER.includes(pageType);
  const uspContent: IUspBanner = isSlider ? USP_PLP : USP_ERROR;

  const showTitle = pageType === 'error';

  const triggerTracking = () => {
    const eventName = `USP_view_${pageType === 'hero_pdp' ? 'plp' : pageType}`;
    const sharedDatas = {
      category: 'view',
      action: 'page_section',
      label: 'usp_block',
    };
    const datas = { ...sharedDatas, property: pageType };
    sendAnalyticEvent(eventName, datas);
  };

  const { containerRef } = useOnReveal(useCallback(() => triggerTracking(), [pageType]));

  return (
    <div
      ref={containerRef}
      className={clsx(styles.usp, isSlider && styles['usp--slider'])}
      data-cy={isSlider ? 'usp_block_catalog' : 'usp_block_error'}
    >
      {showTitle && uspContent?.title && <h2 className={styles.usp__title}>{t(uspContent?.title)}</h2>}
      <section className={clsx(styles.usp__blockContainer)}>
        <ul className={styles.usp__blockList}>
          {uspContent?.list?.map((item: UspBlock) => {
            return (
              <li
                className={styles.usp__block}
                key={item.title}
              >
                <i className={clsx(styles.usp__block__icon)}>
                  <Icon name={item.icon} />
                </i>
                <h3 className={styles.usp__block__title}>
                  <ConditionalWrapper
                    condition={pageType !== 'error'}
                    wrap={(children) => (
                      <a
                        href={item.link}
                        rel="noopener"
                        tabIndex={0}
                      >
                        {children}
                      </a>
                    )}
                  >
                    {t(item.title)}
                  </ConditionalWrapper>
                </h3>
                <p>{t(item.description)}</p>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export default Usp;
