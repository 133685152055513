enum UserBadge {
  Pro = 'pro',
  Recommended = 'recommended',
  Depositor = 'depositor',
  ProductReporter = 'productReporter',
  DirectShipping = 'direct-shipping',
  FashionActivist = 'fashion-activist',
  TrustedSeller = 'trusted-seller',
  ExpertSeller = 'expert-seller',
  OfficialStore = 'official-store',
}

export default UserBadge;
