import { invertMap } from '@helpers/utils/general';
import { RegionId, RegionName } from '@interfaces/models/region';
import { AvailableLocales } from '@interfaces/models/locale';
import { AvailableSubdomains } from '@interfaces/models/environment';
import { SupportedLanguages } from '@interfaces/models/language';
import { AvailableCountryISOCodes } from '@interfaces/models/country';

export const regionNameToRegionIdMap = new Map<RegionName, RegionId>([
  ['FR', 1],
  ['UK', 4],
  ['WW', 5], // Worldwide
  ['US', 6],
  ['DE', 10],
  ['IT', 11],
  ['ES', 12],
  ['RE', 13], // Rest of Europe
  ['FI', 14],
  ['SE', 15],
  ['DK', 16],
  ['AU', 17],
  ['HK', 18],
  ['SG', 19],
  ['KR', 20],
  ['NL', 21],
  ['BE', 22],
]);

export const regionIdToRegionNameMap = invertMap<RegionId, RegionName>(regionNameToRegionIdMap);

export const localeToSubdomainMap = new Map<AvailableLocales, AvailableSubdomains>([
  ['en-GB', 'www'],
  ['en-US', 'us'],
  ['fr-FR', 'fr'],
  ['de-DE', 'de'],
  ['es-ES', 'es'],
  ['it-IT', 'it'],
  ['ko-KR', 'kr'],
  ['nl-NL', 'nl'],
  ['zh-HK', 'hk'],
  ['sv-SE', 'sv'],
]);

export const subdomainToLocaleMap = invertMap<AvailableSubdomains, AvailableLocales>(localeToSubdomainMap);

export const languageToLocaleMap: Map<SupportedLanguages, AvailableLocales> = new Map([
  ['de', 'de-DE'],
  ['en', 'en-GB'],
  ['es', 'es-ES'],
  ['fr', 'fr-FR'],
  ['it', 'it-IT'],
  ['ko', 'ko-KR'],
  ['us', 'en-US'],
  ['hk', 'zh-HK'],
  ['nl', 'nl-NL'],
  ['sv', 'sv-SE'],
]);

export const localeToLanguageMap = invertMap<AvailableLocales, SupportedLanguages>(languageToLocaleMap);

export const hwWidgetLocaleMap: Record<SupportedLanguages, string> = {
  hk: 'zh',
  es: 'es',
  it: 'it',
  fr: 'fr',
  de: 'de',
  us: 'us',
  nl: 'nl',
  en: 'en',
  ko: 'ko',
  sv: 'sv',
};

export const NEW_LANGUAGE_FF_MAPPING = {
  'zh-HK': 'enableHkLanguageTraditionalChinese',
  'nl-NL': 'enableDutchLanguage',
  'sv-SE': 'enableSwedishLanguage',
};

export const MIGRATION_SITE_INFO: {
  [k in AvailableCountryISOCodes]?: {
    ffName: string;
    oldSiteId: `${RegionId}`;
  };
} = {
  BE: {
    ffName: 'beSiteIdEnableStatus',
    oldSiteId: '1',
  },
  NL: {
    ffName: 'nlSiteIdEnableStatus',
    oldSiteId: '13',
  },
};
