import type { LDClient, LDFlagValue, LDFlagSet, LDEvaluationDetail } from 'launchdarkly-js-client-sdk';

export enum LDState {
  INITIALIZING = 'initializing',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}
export type BucketTestProps = {
  flags: LDFlagSet;
  ssr?: boolean;
  defaultFeatures?: LDFlagSet;
  state: LDState;
};
export type FeatureSelector = (features: LDFlagSet) => boolean;
export type VariationSelector = FeatureSelector;
export type BucketTestState = {
  state: LDState;
  touchedFlags: Map<string, LDFlagValue>;
  features: LDFlagSet;
  isFeatureEnabled: (fs: FeatureSelector, sendEvent?: boolean) => ReturnType<typeof fs>;
  getFeature: (flagName: string, defaultValue?: LDFlagValue) => LDEvaluationDetail;
};
export type LDDataShape = {
  client: LDClient;
  flags: LDFlagSet;
  state: LDState;
};
