export const isGoogleBot = (userAgent: string | undefined) => {
  const regexBot = new RegExp(/googlebot|Google|Googlebot-Mobile|Googlebot-Image/, 'i'); // to be replaced by @config/botPattern later
  let isUserAGoogleBot = false;

  if (userAgent) {
    return regexBot.test(userAgent);
  }

  if (typeof navigator !== 'undefined') {
    isUserAGoogleBot = regexBot.test(navigator?.userAgent);
  }
  return isUserAGoogleBot;
};

export const isBot = (userAgent?: string) => isGoogleBot(userAgent);
