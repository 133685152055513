import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { ComponentPageType } from '@interfaces/common/common';

const usePageType = () => {
  const { data: pageType } = useQuery<ComponentPageType>({
    queryKey: [GlobalQueryKeys.PAGE_TYPE],
    staleTime: Infinity,
  });

  return {
    pageType,
  };
};

export default usePageType;
