import React, { FCWithChildren, ReactElement, ReactNode } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrap: (children: ReactNode) => ReactElement;
  defaultWrapper?: (children: ReactNode) => ReactElement;
}

const ConditionalWrapper: FCWithChildren<ConditionalWrapperProps> = ({
  condition,
  wrap,
  children,
  defaultWrapper = (children: ReactNode): ReactElement => <>{children}</>,
}): ReactElement => (condition ? wrap(children) : defaultWrapper(children));

export default ConditionalWrapper;
