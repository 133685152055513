import React, { FCWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import Environment from '@config/index';
import { usePreferences } from '@context/preferences.context';
import logger from '@helpers/utils/logger/client';
import { LDOptions, camelizeFlagsAndMap, getOrCreateLDCookies } from '@components/bucketTest/bucket.utils';
import { BucketTestProps, BucketTestState, FeatureSelector, LDState } from '@components/bucketTest/types';
import useUser from '@hooks/user/use-user';

const BucketTestContext = React.createContext<BucketTestState>({} as BucketTestState);

const useBucket = () => useContext(BucketTestContext);

const BucketTestProvider: FCWithChildren = ({ children }) => {
  const [{ flags, state }, setData] = useState<BucketTestProps>({
    flags: {},
    state: LDState.INITIALIZING,
  });
  const client = useRef<LDClient>();
  const touchedFlagsRef = useRef<BucketTestState['touchedFlags']>(new Map());
  const flagsMaps = useRef<Record<string, string>>();
  const { country, language } = usePreferences();
  const { user, isAuthenticated } = useUser();
  const { anonymousId, randomRangeId } = getOrCreateLDCookies();
  const addToTouchedFlags = (key, value) => touchedFlagsRef.current.set(key, value);
  const allFlagsProxy = new Proxy(
    {},
    {
      get: (_, prop) => {
        if (touchedFlagsRef.current.has(prop.toString())) {
          return touchedFlagsRef.current.get(prop.toString());
        }
        const value = client.current?.allFlags()[flagsMaps.current[String(prop)]];
        addToTouchedFlags(prop, value);
        return value;
      },
    },
  );
  const variationsProxy = new Proxy(
    {},
    {
      get: (_, prop) => {
        if (touchedFlagsRef.current.has(prop.toString())) {
          return touchedFlagsRef.current.get(prop.toString());
        }
        const value = client.current?.variationDetail(flagsMaps.current[String(prop)]);
        addToTouchedFlags(prop, value);
        return value;
      },
    },
  );

  const variationsValueProxy = new Proxy(
    {},
    {
      get: (_, prop) => {
        return variationsProxy[prop].value;
      },
    },
  );

  const isFeatureEnabled = (fs: FeatureSelector, sendEvent = false) =>
    flagsMaps.current ? (sendEvent ? fs(variationsValueProxy) : fs(allFlagsProxy)) : undefined;

  const getFeature: BucketTestState['getFeature'] = (flagName, sendEvent = false) =>
    flagsMaps.current
      ? sendEvent
        ? variationsProxy[flagName]
        : { value: allFlagsProxy[flagName], reason: { kind: undefined } }
      : { value: undefined, reason: { kind: undefined } };

  const LDUser = useMemo(() => {
    return {
      kind: 'user',
      key: user?.id || randomRangeId || 'anonymous',
      email: user?.email,
      name: user?.username,
      country,
      logged: !!isAuthenticated,
      env: 'fenx',
      language,
      anonymous_id: anonymousId,
      device_id: anonymousId,
      platform: 'web',
      ruleContext: [country],
      internal_user: String(user?.email).endsWith('@vestiairecollective.com'),
      sdk: 'new',
    };
  }, [user]);

  useEffect(() => {
    if (client.current || state === LDState.FAILED) {
      return;
    }
    client.current = initialize(Environment.launch_darkly.clientSideID, LDUser, LDOptions);
    client.current
      .waitForInitialization()
      .then(() => {
        const [flags, mappings] = camelizeFlagsAndMap(client.current.allFlags());
        flagsMaps.current = mappings;
        setData({
          flags,
          state: LDState.SUCCESS,
        });
      })
      .catch(() => {
        setData({ flags: {}, state: LDState.FAILED });
        logger.error('LD initialization failed');
      });
  }, [LDUser]);

  useEffect(() => {
    if (state !== LDState.SUCCESS) {
      return;
    }
    const currentldUser = client.current.getContext();
    if (LDUser.email === currentldUser.email) {
      return;
    }
    setData((currentState) => ({ ...currentState, state: LDState.PENDING }));
    client.current.identify(LDUser).then((newFlags) => {
      const [flags, mappings] = camelizeFlagsAndMap(newFlags);
      flagsMaps.current = mappings;
      setData((currentState) => ({
        ...currentState,
        flags,
        state: LDState.SUCCESS,
      }));
    });
  }, [LDUser, state]);

  return (
    <BucketTestContext.Provider
      value={{
        state,
        touchedFlags: touchedFlagsRef.current,
        features: flags,
        isFeatureEnabled,
        getFeature,
      }}
    >
      {children}
    </BucketTestContext.Provider>
  );
};

const BucketTestHOC = <T,>(WrappedComponent: React.FC<T>): React.FC<T> => {
  const InnerComponent = (props: T) => (
    <BucketTestProvider>
      <WrappedComponent {...props} />
    </BucketTestProvider>
  );
  return InnerComponent;
};

export { BucketTestProvider, useBucket, BucketTestHOC, BucketTestContext };
