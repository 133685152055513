import React, { SVGAttributes } from 'react';
import Environment from '@config/index';
import clsx from 'clsx';
import styles from './icon.module.scss';

type IconProps = SVGAttributes<HTMLOrSVGElement> & {
  name: string;
  disableDefaultStyling?: boolean;
};

const Icon: React.FC<IconProps> = (props) => {
  const { name, className, disableDefaultStyling = false, ...rest } = props;

  return (
    <span className={className}>
      {/* @ts-ignore */}
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={clsx(!disableDefaultStyling && styles['vc-icon'])}
        {...rest}
      >
        <use href={`${Environment.svgSpritePath}/${name}.svg#${name}`} />
      </svg>
    </span>
  );
};

export default Icon;
