import UserBadge from '@enums/userBadge';
import { Address } from './address';
import { Language } from './language';
import { Picture } from './picture';
import { Url } from './url';
import { UserApp } from './userApp';
import { UserCivility } from './userCivility';
import { UserSellerRating } from './userSellerRating';
import { UserSocial } from './userSocial';
import { UserStatistics } from './userStatistics';
import { UserVacation } from './userVacation';

export interface User {
  id: string;
  type: 'user' | 'pdp_seller';
  /**
   * user firstname
   */
  firstname: string;
  /**
   * User name
   */
  username?: string;
  /**
   * user email
   */
  email?: string;
  /**
   * a flag which shows if a client has a wallet
   */
  hasWallet?: boolean;
  /**
   * a flag which shows if hyper wallet is active or if client id is whitelisted
   */
  hyperwalletActive?: boolean;
  /**
   * a flag that represents if the user already deposited a product
   */
  alreadyDepositedAProduct?: boolean;
  picture?: Picture;
  cover?: Picture;
  url?: Url;
  /**
   * user mood
   */
  mood?: string;
  /**
   * Birth date (AAAA-MM-DDT00:00:00Z)
   */
  birthDate?: Date;
  /**
   * Seller country
   */
  country?: string;
  /**
   * Inscription date (AAAA-MM-DDT00:00:00Z)
   */
  inscriptionDate?: Date;
  /**
   * Last login date (AAAA-MM-DDT00:00:00Z)
   */
  lastActive?: Date;
  civility?: UserCivility;
  social?: UserSocial;
  statistics?: UserStatistics;
  sellerRating?: UserSellerRating;
  /**
   * User is official store
   */
  isOfficialStore?: boolean;
  language: Language;
  /**
   * User right to report a product
   */
  productReporter?: boolean;
  badges?: UserBadge[];
  apps?: UserApp[];
  /**
   * VC's Internal Account
   */
  internalAccount?: boolean;
  vacation?: UserVacation;
  segment?: 'C2C' | 'VIP' | 'B2C';
  /**
   * Direct Shipping seller type
   */
  dsSellerType?: string;
  /**
   * [Relationship] User's address
   */
  address?: Address;
  /**
   * Eligibility to new cart
   */
  newCart?: boolean;
}

export type UnavailabilityPayload = {
  date_begin: string;
  date_end: string;
};

export enum UTMCookies {
  UTM_CAMPAIGN = 'utm_campaign',
}
