import { RegionId, RegionName } from '@interfaces/models/region';
import {
  languageToLocaleMap,
  localeToLanguageMap,
  localeToSubdomainMap,
  regionIdToRegionNameMap,
  regionNameToRegionIdMap,
  subdomainToLocaleMap,
} from '@config/localization';
import { AvailableSubdomains } from '@interfaces/models/environment';
import { AvailableLocales } from '@interfaces/models/locale';
import { SupportedLanguages } from '@interfaces/models/language';

export const getRegionNameById = (regionId: RegionId, fallback?: RegionId): RegionName => {
  return regionIdToRegionNameMap.get(regionId) ?? regionIdToRegionNameMap.get(fallback);
};

export const getRegionIdByRegionName = (regionName: RegionName, fallback?: RegionName): RegionId => {
  return regionNameToRegionIdMap.get(regionName) ?? regionNameToRegionIdMap.get(fallback);
};

export const getLocaleBySubdomain = (
  subdomain: AvailableSubdomains,
  fallback?: AvailableSubdomains,
): AvailableLocales => {
  return subdomainToLocaleMap.get(subdomain) ?? subdomainToLocaleMap.get(fallback);
};

export const getSubdomainByLocale = (locale: AvailableLocales, fallback?: AvailableLocales): AvailableSubdomains => {
  return localeToSubdomainMap.get(locale) ?? localeToSubdomainMap.get(fallback);
};

export const getLanguageByLocale = (locale: AvailableLocales, fallback?: AvailableLocales): SupportedLanguages => {
  return localeToLanguageMap.get(locale) ?? localeToLanguageMap.get(fallback);
};

export const getLocaleByLanguage = (locale: SupportedLanguages, fallback?: SupportedLanguages): AvailableLocales => {
  return languageToLocaleMap.get(locale) ?? languageToLocaleMap.get(fallback);
};

/* The local variable in FAQ links requires a specific mapping */
export const getFAQLocale = (locale: AvailableLocales): string => {
  return /^(en|zh)/.test(locale) ? locale.toLowerCase() : localeToLanguageMap.get(locale);
};
