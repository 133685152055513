import { SnowPlowEventPayload } from '@interfaces/models/internal/analytics-event/product-event';

import listingForm from './listing/listingForm';
import pricingCalculator from './listing/pricingCalculator';

/**
 * The below extracts the`eventsCatalogPayload` object in `analytic-events.ts`
 * This will serve as a mounting object for use by the analytic hook.
 */
const eventsPayload: Record<string, Record<string, Partial<SnowPlowEventPayload>>> = {
  listingForm,
  pricingCalculator,
};

export default eventsPayload;
