/**
 * Data Schemas.
 *
 * A schema represents the structure and expected values of the list of
 * analytics data to send the analytics sources after a given event has
 * occurred e.g. a "Product" schema may have values specific only to
 * products, such as productId and productCategory which we send
 * when displaying a product item.
 */

import { Product } from './product';

export interface DataLayerState {
  [variableName: string]: string;
}

export interface I18nState {
  id_site?: string;
  env_country?: string;
  env_language?: string;
  order_currency?: string;
  ISO_country_code?: string;
}

export interface DeviceState {
  /**
   * User is viewing the website on a small screen (responsive mode).
   * Set to `true` when on the "mobile" version with burger menu.
   */
  responsive: BooleanString.True | BooleanString.False;

  /**
   * Orientation of the screen.
   */
  orientation?: Orientation.Portrait | Orientation.Landscape;

  /**
   * User is in private browsing or not.
   */
  privateBrowsing?: BooleanString.True | BooleanString.False;
}

export enum BooleanString {
  True = 'true',
  False = 'false',
}

export enum YesNoString {
  Yes = 'yes',
  No = 'no',
}

export enum Orientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export enum UserAccountLevel {
  Regular = 'regular',
}

export enum NonLogged {
  Value = 'non-logged',
}

/**
 * User Category.
 *
 * - `Visitor` when the user has neither purchased nor sold anything yet on Vestiaire,
 * - `Buyer` when the user has already placed at least 1 order but has not sold anything yet,
 * - `Seller` when the user has already sold an item but has not purchased anything yet,
 * - `Mixed` when the user has both placed at least 1 order and has sold at least 1 item.
 */
export enum UserCategory {
  Visitor = 'visitor',
  Buyer = 'buyer',
  Seller = 'seller',
  Mixed = 'mixed',
}

/**
 * Buyer Status.
 *
 * - `Viewer` when the user has not purchased anything yet,
 * - `FirstBuyer` when the user has already made 1 order,
 * - `RepeatedBuyer` when the user has already made more than 2 orders,
 * - `TopBuyer` when the user spent more than 3.5K€ in the past 12 months.
 */
export enum BuyerStatus {
  Viewer = 'viewer',
  FirstBuyer = 'first buyer',
  RepeatedBuyer = 'repeated buyer',
  TopBuyer = 'top buyer',
}

/**
 * Seller Status.
 *
 * - `Viewer` when the user has not sold anything yet,
 * - `FirstSeller` when the user has already posted 1 sell,
 * - `RepeatedSeller` when the user has already posted more than 2 sells
 * - `TopSeller` when the user sold for more than 5K€ in the past 12 months.
 * - `deposant` when the user has deposit products and no products sold.
 */
export enum SellerStatus {
  Viewer = 'viewer',
  FirstSeller = 'first seller',
  RepeatedSeller = 'repeated seller',
  TopSeller = 'top seller',
  Depositor = 'first deposant',
}

/**
 * Seller Rating for tracking
 *
 * - `standard` is tracking equivalent of "Common" seller rating badge
 * - `Trusted` & `Expert` are exposed as below
 */
export enum TrackingSellerRating {
  Common = 'standard',
  Trusted = 'trusted seller',
  Expert = 'expert seller',
}

/**
 * first three products displayed in a pagelist
 *
 * - `list_products_unitprice_ati` Price in local currency
 * - `list_products_unitprice_ati_eur` Price in EUR currency
 */

export interface UserState {
  cust_firstname?: string;
  user_id?: string;
  user_email: string;
  user_accountLevel: UserAccountLevel.Regular | NonLogged.Value;
  user_category: UserCategory | NonLogged.Value | '';
  user_buyerStatus: BuyerStatus | NonLogged.Value | '';
  user_sellerStatus: SellerStatus | NonLogged.Value | '';
  user_iphoneApp: BooleanString.True | BooleanString.False | NonLogged.Value;
  user_androidApp: BooleanString.True | BooleanString.False | NonLogged.Value;
  user_seller_rating: TrackingSellerRating | NonLogged.Value;
  user_fashion_activist: BooleanString.True | BooleanString.False | NonLogged.Value;
}

export interface ListProductsState {
  list_products: ListProduct[];
}

export interface ListProduct {
  list_products_brand: string;
  list_products_id: string;
  list_products_name: string;
  list_products_unitprice_ati: string;
  list_products_unitprice_ati_eur?: string;
  list_products_url_page: string;
  list_products_url_picture: string;
}

export interface PageUrlState {
  pageUrl: string;
}

export interface MmaoData {
  product: Product;
  userRole: string;
}
