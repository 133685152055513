import React, { FCWithChildren, useRef, useState } from 'react';
import { createContext } from 'use-context-selector';
import { DialogProps } from '@components/atoms/dialog/dialog';
import dynamic from 'next/dynamic';

const Dialog = dynamic(() => import('@components/atoms/dialog/dialog'), {
  ssr: false,
});

type ModalContextValues = {
  openModal: (children: React.ReactNode, dialogProps?: Partial<DialogProps>) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextValues>({} as never);

const ModalProvider: FCWithChildren = (props): React.JSX.Element => {
  const { children } = props;

  const modalChildren = useRef<React.ReactNode | null>(null);
  const dialogProps = useRef<DialogProps>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (children: React.ReactNode, props?: DialogProps): void => {
    modalChildren.current = children;
    dialogProps.current = {
      ...props,
      // Set default content styles if none are set manually
      ...(!props?.styles?.content && {
        styles: {
          content: {
            maxWidth: '800px',
            width: '100%',
          },
        },
      }),
    };
    setIsModalOpen(true);
  };

  const closeModal = (): void => {
    modalChildren.current = null;
    dialogProps.current = null;
    setIsModalOpen(false);
  };

  const value: ModalContextValues = {
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {isModalOpen && (
        <Dialog
          hasCloseIcon
          {...dialogProps.current}
          isOpen={isModalOpen}
          closeDialog={closeModal}
        >
          {modalChildren.current}
        </Dialog>
      )}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
