import React, { FCWithChildren } from 'react';
import Head from 'next/head';
import { GetServerSideProps, NextPageWithLayout } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import clsx from 'clsx';
import extractFromContext from '@helpers/utils/extract-from-context';
import styles from '@styles/pages/error.module.scss';
import Icon from '@components/atoms/icon/icon';
import Environment from '@config/environment';
import Usp from '@components/organisms/usp/usp';
import dynamic from 'next/dynamic';
import { getAppInitialProps } from '@helpers/initial-props';

const DefaultLayout = dynamic(() => import('@layouts/default-layout')) as FCWithChildren;

export type ErrorProps = {
  statusCode: number;
};

const trendings = [
  {
    img: '/images/notFound/daily-deals.png',
    title: 'daily deals',
    link: '/l/channels/dailydeals-blt4e5ccff94bbd82e3/',
  },
  { img: '/images/notFound/new-arrivals.png', title: 'new arrivals', link: '/new-items/' },
  { img: '/images/notFound/we-love.png', title: 'we love', link: '/we-love/' },
  { img: '/images/notFound/authentication.png', title: 'fast & authenticated', link: '/g/express-delivery/' },
];

const NotFound = (): React.JSX.Element => {
  const { t } = useTranslation('default-layout');

  return (
    <>
      <div className={styles.notFound__greyLine}>
        <div className={clsx(styles.notFoundContainer, 'innerContainer')}>
          <h2 className={clsx(styles.notFoundContainer__title, 'vc-title-s')}>{t('ERROR_PAGE.PAGE_NOT_FOUND')}</h2>
          <span>{t('ERROR_PAGE.LOOKING_FOR')}</span>
          <div className={clsx(styles.notFoundContainer__buttons)}>
            <a
              href="/"
              className="vc-btn vc-btn--rounded vc-btn--large"
            >
              {t('ERROR_PAGE.GO_HOME_PAGE')}
            </a>
            <a
              href="https://faq.vestiairecollective.com/"
              className="vc-btn vc-btn--rounded-secondary vc-btn--large"
            >
              {t('ERROR_PAGE.VISIT_FAQ')}
            </a>
          </div>
        </div>
      </div>
      <div className={clsx(styles.mostTrendingContainer, 'innerContainer')}>
        <h2 className={clsx('vc-title-s')}>{t('ERROR_PAGE.MOST_TRENDING')}</h2>
        <ul className={clsx(styles.mostTrendingContainer__list)}>
          {trendings.map(({ img, title, link }) => (
            <li
              key={title}
              className={clsx(styles.mostTrendingContainer__list__item)}
            >
              <a href={link}>
                <img
                  src={`${Environment.assetsPath}${img}`}
                  alt={title}
                  width={300}
                  height={200}
                />
                <div className={clsx(styles.mostTrendingContainer__list__item__title, 'vc-text-s')}>{title}</div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.notFound__greyLine}>
        <div className="innerContainer">
          <Usp pageType="error" />
        </div>
      </div>
    </>
  );
};

export const DefaultError = (): React.JSX.Element => {
  const { t } = useTranslation('default-layout');

  return (
    <div className={clsx(styles.errorContainer, 'innerContainer')}>
      <Icon
        name="issue"
        className={styles.errorContainer__icon}
      />
      <h2 className="vc-title-s">Error</h2>
      <div className={styles.errorContainer__information}>{t('ERROR_PAGE.PLEASE_RELOAD')}</div>
      <button
        className="vc-btn vc-btn--primary vc-btn--large"
        onClick={() => window.location.reload()}
      >
        {t('ERROR_PAGE.RELOAD_BUTTON')}
      </button>
    </div>
  );
};

const ErrorPage: NextPageWithLayout<ErrorProps> = (props): React.JSX.Element => {
  const { statusCode } = props;
  const { t } = useTranslation('common');

  const title: string = statusCode === 404 ? t('NOT_FOUND.TITLE.404') : t('NOT_FOUND.TITLE.DEFAULT');

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <section>{statusCode === 404 ? <NotFound /> : <DefaultError />}</section>
    </>
  );
};

ErrorPage.Layout = [DefaultLayout];
ErrorPage.PageType = 'ErrorPage';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const statusCode = context?.res ? context.res.statusCode : 404;

  const { locale } = extractFromContext(context);

  const initialProps = await getAppInitialProps(context);
  const translations = await serverSideTranslations(locale, ['default-layout', 'common']);

  return {
    props: {
      ...initialProps,
      ...translations,
      statusCode,
    },
  };
};

export default ErrorPage;
