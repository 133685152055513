import React, { useState, createContext, useContext, FCWithChildren, useEffect, useMemo } from 'react';
import { debounce } from '@helpers/utils/general';
import { DeviceType } from '@enums/deviceType';

type WindowSize = {
  height: number;
  width: number;
};

type DeviceSizeContextValues = {
  windowSize: WindowSize;
  deviceType: DeviceType;
  hasWindowSizeBeenSet: boolean;
};

type DeviceProviderProps = {
  initialDeviceWindowSize?: WindowSize;
  initialDeviceType?: DeviceType;
};

const DeviceSizeContext = createContext<DeviceSizeContextValues>({} as never);

const DeviceSizeProvider: FCWithChildren<DeviceProviderProps> = (props) => {
  const {
    initialDeviceWindowSize = {
      height: 0,
      width: 0,
    },
    initialDeviceType,
    children,
  } = props;

  const [windowSize, setWindowSize] = useState<WindowSize>(initialDeviceWindowSize);
  const [deviceType, setDeviceType] = useState<DeviceType>(initialDeviceType);
  const [hasWindowSizeBeenSet, setHasWindowSizeBeenSet] = useState(false);

  useEffect(() => {
    const userAgent: string = window.navigator.userAgent;
    // ios device edge case
    if (window.navigator.maxTouchPoints > 2 && (userAgent.match(/MacIntel/i) || userAgent.match(/Macintosh/i))) {
      setDeviceType(DeviceType.IOS);
    }

    const debouncedHandleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setHasWindowSizeBeenSet(true);
    }, 20);

    debouncedHandleResize();
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  const value = useMemo<DeviceSizeContextValues>(() => {
    return {
      windowSize,
      deviceType,
      hasWindowSizeBeenSet,
    };
  }, [windowSize, deviceType, hasWindowSizeBeenSet]);

  return <DeviceSizeContext.Provider value={value}>{children}</DeviceSizeContext.Provider>;
};

const useDeviceSize = () => useContext(DeviceSizeContext);

export { DeviceSizeProvider, useDeviceSize };
