import React, { FCWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider, Hydrate, DehydratedState } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Environment from '@config/index';

type QueryContextProps = {
  dehydratedState: DehydratedState;
};

const QueryProvider: FCWithChildren<QueryContextProps> = (props) => {
  const { dehydratedState, children } = props;

  const [queryClient] = useState<QueryClient>(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // Disable cache on server, set it to 5 min on client
            // cacheTime: typeof window === 'undefined' ? 0 : 1000 * 60 * 5
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        {Environment.envName === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
        {children}
      </Hydrate>
    </QueryClientProvider>
  );
};

export default QueryProvider;
