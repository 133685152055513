/**
 * Each entry in the below object shall take the below form:
 *
 * key: event name
 * value: snowplow event that maps to a tracking plan
 */

const EVENT_CATEGORY = 'submit_an_item';

export default {
  submit_universe: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_universe',
    eventLabel: '',
    eventProperty: '',
  },
  submit_category: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_category',
    eventLabel: '',
    eventProperty: '',
  },
  submit_brand_list: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'select_brand',
    eventLabel: '',
    eventProperty: '',
  },
  submit_brand_next: {
    eventCategory: EVENT_CATEGORY,
    eventAction: 'brand_next',
    eventLabel: '',
    eventProperty: '',
  },
  submit_draft_creation: {
    eventCategory: 'list_an_item',
    eventAction: 'create_draft',
    eventLabel: 'web',
    eventProperty: '',
    preduct_id: '',
  },
};
