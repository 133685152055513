import OrdersService from '@services/orders-service';
import React, { FCWithChildren, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Order } from '@interfaces/models/order';
import useLocalStorage from '@hooks/use-local-storage';
import { Product } from '@interfaces/models/product';
import { createContext } from 'use-context-selector';
import { OrderLine } from '@interfaces/models/orderLine';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import TagContainer from '@enums/tagContainer';
import { useAnalytics } from '@context/analytics.context';
import { useBucket } from '@context/bucket.context';
import { LDState } from '@components/bucketTest/types';
import Environment from '@config/index';
import logger from '@helpers/utils/logger/client';
import useUser from '@hooks/user/use-user';

type CartContextValues = {
  cart: Order;
  removeFromShoppingCart: (orderLineId: OrderLine['id'], productId: Product['id']) => Promise<void>;
  addToShoppingCart: (product: Product, includeSnowplowCall: boolean) => Promise<OrderLine>;
  isCartProcessing: boolean;
  productsInCart: Product[];
  isProductIdInCart: (productsInCart: Product[], productId: Product['id']) => boolean;
  cartModalInfo: CartModalInfoType;
  setCartModalInfo: React.Dispatch<React.SetStateAction<CartModalInfoType>>;
  hasCartUpdateErrorOccurred: boolean;
  cartUpdateErrorMessage: string;
  cartUrl: string | null;
  checkoutUrl: string | null;
  isCartPreviewOpened: boolean;
  setIsCartPreviewOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

type InitialOrderValue = {
  id?: Order['id'];
  orderLines?: OrderLine[];
  itemsNumber: number;
  newCart?: boolean;
};

type CartModalInfoType = {
  product: Product | null;
  isOpen: boolean;
};

const initialOrderValue: InitialOrderValue = {
  itemsNumber: 0,
};

const extractProductsFromCart = (cart: Order | InitialOrderValue): Product[] => {
  return (cart?.orderLines ?? [])
    .map((orderLine: OrderLine) => orderLine?.product)
    .filter((product: Product) => !!product);
};

const CartContext = createContext<CartContextValues>({} as never);

const CartProvider: FCWithChildren = (props): React.JSX.Element => {
  const { children } = props;
  const { pathname } = useRouter();
  const { updateDataLayer, sendEvent } = useAnalytics();
  const { sendAnalyticEvent } = useAnalyticEvents('product');
  const { isAuthenticated } = useUser();

  const [cart, setCart] = useLocalStorage<Order | InitialOrderValue>('shoppingCartFetchItems', initialOrderValue);
  const [productsInCart, setProductsInCart] = useState<Product[]>(extractProductsFromCart(cart));
  const [isCartProcessing, setIsCartProcessing] = useState<boolean>(false);
  const [cartModalInfo, setCartModalInfo] = useState<CartModalInfoType>({} as CartModalInfoType);
  const [hasCartUpdateErrorOccurred, setHasCartUpdateErrorOccurred] = useState<boolean>(false);
  const [cartUpdateErrorMessage, setCartUpdateErrorMessage] = useState<string>('');
  const [isCartPreviewOpened, setIsCartPreviewOpened] = useState<boolean>(false);
  const { state: ldStatus } = useBucket();

  const cartUrl = `${Environment.baseHost}${Environment.checkoutBaseUrl}/cart`;
  const checkoutUrl = `${Environment.baseHost}${Environment.checkoutBaseUrl}/checkout`;

  const getItemsInCart = async (): Promise<void> => {
    try {
      const getCart: () => Promise<Order | null> = OrdersService.getCart.bind(OrdersService);
      // If cart is empty, API returns en empty response, so reset it to initial cart value
      const cart: Order | InitialOrderValue = (await getCart()) ?? initialOrderValue;
      setCart({ ...cart });
    } catch (err) {
      logger.error(err, 'Error occurred while fetching shopping cart items');
    }
  };

  // IncludeSnowplowCall is used because we call this function in multiple places and not all places trigger analytics
  const addToShoppingCart = async (product: Product, includeSnowplowCall: boolean = true): Promise<OrderLine> => {
    if (!isAuthenticated) {
      return;
    }
    setIsCartProcessing(true);

    if (includeSnowplowCall) {
      sendAnalyticEvent('pdp_add_to_bag');
    }
    sendEvent(
      {
        type: 'event',
        container: TagContainer.Analytics,
        payload: {
          event: 'productAddToCart',
          addToCartType: 'product_page',
          order_currency: product?.price?.currency,
          products: [
            {
              id: product.id,
              name: product.name,
              brand: product?.brand?.name,
              price: product?.price?.cents,
              price_eur: '',
              quantity: '1',
              category: `${product?.universe ? product?.universe?.name?.toLocaleUpperCase() : ''}_${
                product?.category ? product?.category?.name?.toLocaleLowerCase() : ''
              }`,
            },
          ],
        },
      },
      {
        type: 'Add to cart popin',
        container: TagContainer.Media,
      },
    );
    setHasCartUpdateErrorOccurred(false);
    setCartUpdateErrorMessage('');

    // Optimistic design
    // Add product to products in cart
    setProductsInCart([...productsInCart, product]);
    setCartModalInfo({
      isOpen: true,
      product,
    });

    try {
      const orderLine: OrderLine = await OrdersService.addProductToCart({
        idProduct: product.id,
      });
      return orderLine;
    } catch (err) {
      setHasCartUpdateErrorOccurred(true);
      if (err?.response?.data?.errors?.detail) {
        setCartUpdateErrorMessage(err?.response?.data?.errors?.detail);
      }
      logger.error(err, `Error occurred while adding productId: ${product.id} to shopping cart`);
      throw err;
    } finally {
      // Price calculations are done on backend, so we need to re-fetch items
      await getItemsInCart();
      setIsCartProcessing(false);
    }
  };

  const removeFromShoppingCart = async (orderLineId: OrderLine['id'], productId: Product['id']): Promise<void> => {
    if (cart.itemsNumber <= 0) {
      return;
    }
    setIsCartProcessing(true);

    try {
      await OrdersService.removeProductFromCart(orderLineId, productId);
      // Price calculations are done on backend, so we need to re-fetch items
      await getItemsInCart();
    } catch (err) {
      setHasCartUpdateErrorOccurred(true);
      if (err?.response?.data?.errors?.detail) {
        setCartUpdateErrorMessage(err?.response?.data?.errors?.detail);
      }
      logger.error(err, `Error occurred while removing orderLineId: ${orderLineId} from shopping cart`);
    } finally {
      setIsCartProcessing(false);
    }
  };

  const isProductIdInCart = (productsInCart: Product[], productId: Product['id']): boolean => {
    return productsInCart.some((product: Product) => productId === product.id);
  };

  const clearCart = () => {
    setCart(initialOrderValue);
  };

  useEffect(() => {
    // Update current productIds in cart on cart change
    setProductsInCart(extractProductsFromCart(cart));
    // temp solution: preventing populating the `order_id` datalayer, since the cart is empty and we are in the `/order-confirmation` page.
    if (pathname !== '/order-confirmation') {
      updateDataLayer({ order_id: cart?.id ?? '' });
    }
  }, [cart]);

  useEffect(() => {
    if (isAuthenticated && ![LDState.INITIALIZING, LDState.PENDING].includes(ldStatus)) {
      getItemsInCart();
    } else {
      clearCart();
    }
  }, [isAuthenticated, ldStatus]);

  const value = useMemo<CartContextValues>(() => {
    return {
      cart,
      isProductIdInCart,
      removeFromShoppingCart,
      addToShoppingCart,
      productsInCart,
      isCartProcessing,
      cartModalInfo,
      setCartModalInfo,
      hasCartUpdateErrorOccurred,
      cartUpdateErrorMessage,
      cartUrl,
      checkoutUrl,
      isCartPreviewOpened,
      setIsCartPreviewOpened,
    };
  }, [
    cart,
    isAuthenticated,
    productsInCart,
    isCartProcessing,
    cartModalInfo,
    hasCartUpdateErrorOccurred,
    cartUpdateErrorMessage,
    cartUrl,
    checkoutUrl,
    isCartPreviewOpened,
    setIsCartPreviewOpened,
  ]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartProvider, CartContext };
