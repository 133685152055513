import React, { FCWithChildren } from 'react';
import QueryProvider from '@context/query.provider';
import { DeviceSizeProvider } from '@context/device-size.context';
import { AnalyticsProvider } from '@context/analytics.context';
import { PreferencesProvider } from '@context/preferences.context';
import { UserProvider, UserProviderProps } from '@context/user.context';
import { BucketTestProvider } from '@context/bucket.context';
import { CartProvider } from '@context/cart.context';
import { AuthenticationProvider } from '@context/authentication.context';
import { ModalProvider } from '@context/modal.context';
import MissingLayoutPropertyError from '@errors/layout/missing-layout-property-error';
import IncorrectLayoutTypeError from '@errors/layout/incorrect-layout-type-error';
import { DehydratedState } from '@tanstack/react-query';
import { Preferences } from '@interfaces/models/preferences';
import { DeviceType } from '@enums/deviceType';

export const appWithLayout = (WrappedComponent: React.ReactNode, Layout: FCWithChildren[]) => {
  if (typeof Layout === 'undefined') {
    throw new MissingLayoutPropertyError();
  }
  if (!Array.isArray(Layout)) {
    throw new IncorrectLayoutTypeError();
  }
  if (!Layout.length) {
    return WrappedComponent;
  }

  const InitialComponent: FCWithChildren = ({ children }) => <>{children}</>;
  const ComposedLayouts: FCWithChildren = Layout.reduce(
    (AccumulatedLayouts: FCWithChildren, CurrentLayout: FCWithChildren) =>
      ({ children }) => (
        <AccumulatedLayouts>
          <CurrentLayout>{children}</CurrentLayout>
        </AccumulatedLayouts>
      ),
    InitialComponent,
  );

  return <ComposedLayouts>{WrappedComponent}</ComposedLayouts>;
};

export type AppWithProvidersProps = {
  dehydratedState: DehydratedState;
  preferences: Preferences;
  userContextProps: UserProviderProps;
  deviceSize?: {
    height: Window['innerHeight'];
    width: Window['innerWidth'];
  };
  deviceType?: DeviceType;
};

export const appWithProviders = (WrappedComponent: React.ReactNode, props: AppWithProvidersProps) => {
  const { dehydratedState, preferences, userContextProps, deviceSize, deviceType } = props;

  return (
    <QueryProvider dehydratedState={dehydratedState}>
      <DeviceSizeProvider
        initialDeviceWindowSize={deviceSize}
        initialDeviceType={deviceType}
      >
        <AnalyticsProvider>
          <PreferencesProvider preferences={preferences}>
            <UserProvider {...userContextProps}>
              <BucketTestProvider>
                <CartProvider>
                  <AuthenticationProvider>
                    <ModalProvider>{WrappedComponent}</ModalProvider>
                  </AuthenticationProvider>
                </CartProvider>
              </BucketTestProvider>
            </UserProvider>
          </PreferencesProvider>
        </AnalyticsProvider>
      </DeviceSizeProvider>
    </QueryProvider>
  );
};
