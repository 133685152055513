import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export const readLocalStorageJson = <T>(key: string, defaultValue: T): T => {
  try {
    return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

type StateReturn<S> = [S | undefined, Dispatch<SetStateAction<S | undefined>>];

/**
 *
 * @param key - key of value that you need to read from local storage
 * @param defaultValue - value that need to be saved
 * @param defer - defer the initialization of localstorage to hook
 * @returns - the state of the value from local storage
 */
const useLocalStorage = <T>(key: string, defaultValue: T, defer = true): StateReturn<T> => {
  const [state, setState] = useState<T>(defer ? defaultValue : readLocalStorageJson(key, defaultValue));
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      localStorage.setItem(key, JSON.stringify(state));
    } else {
      didMount.current = true;
    }
  }, [state]);

  useEffect(() => {
    if (defer) {
      setState(readLocalStorageJson(key, defaultValue));
    }
  }, []);

  return [state, setState];
};

export default useLocalStorage;
